import React, { useState } from 'react';
import axios from 'axios';
import properties from '../utils/properties';
import { Button, Alert } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function AddPlano(){
    const [status, setStatus] = useState([]);
    const [load, setLoad] = useState([]);
    const loading = { type: 'bars', color: '#DAA520' };
    Alert.show = false;
    const config = { headers: { 'x-access-token': '' } };
    let token;

    const validationSchemaConst = Yup.object().shape({
        descricao: Yup.string().max(50, 'Tamanho máximo do Nome é de 100 caracteres').required('Nome é Obrigatório'),
        valor: Yup.number('Valor deve ser número sem vírgula').integer('Valor deve ser inteiro').required('Valor é obrigatório')
    });
    const formik = useFormik({
        initialValues: {
          descricao: '',
          valor: ''
        },
        validationSchema: validationSchemaConst,
        onSubmit: (values) => {
            console.log('entrou submit');
            setLoad({
                showLoader: true
            });

            const urlAddPlano = `${properties.server_host}/addplano`; 
            token = localStorage.getItem('TOKEN_KEY');
            config.headers['x-access-token'] = token;
            axios.post(urlAddPlano, { values }, config).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    if (response.data.COD === 0) {
                        if (response.data.message) {
                            setStatus({ success: response.data.message });
                        } else {
                            setStatus({ success: 'Erro Atualizando Senha. Tente novamente mais tarde' });
                        }
                        setLoad({
                            showLoader: false
                        });
                    } else if (response.data.COD === 10) {
                        setStatus({ success: 'Plano de Assinatura Cadastrado com Sucesso!' });
                        setLoad({
                            showLoader: false
                        });
                    }
                }
            }).catch(err => console.log(err))
        },
    });

    const { errors, touched } = formik;

    return (
        <>
        <div>
        <h1>Novo Plano</h1>
        <Alert variant="danger" >
            {status ? status.success : ''}
        </Alert>
        <form onSubmit={formik.handleSubmit}> 
        <div className="bg-light border">
            <label htmlFor="descricao">Nome</label>
        </div>
        <div className="bg-light border">
            <input 
                id="descricao"
                name="descricao"
                type="text"
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur} 
                value={formik.values.descricao} />
            {errors.descricao && touched.descricao && (
              <span className="error">{errors.descricao}</span>
            )}
        </div>

        <div className="bg-light border">
            <label htmlFor="valor">Valor</label>
        </div>
        <div className="bg-light border">
            <input 
                type="text" 
                name="valor" 
                id="valor"
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur} 
                value={formik.values.valor} />
            {errors.valor && touched.valor && (
              <span className="error">{errors.valor}</span>
            )}
        </div>
            
            {load.showLoader && (
                        <ReactLoading type={loading.type} color={loading.color} />
            )}
            <Button variant="secondary" type="submit" > Cadastrar </Button>
        </form>
         
    </div>

    </>
    );
}

export default AddPlano;