import './App.css';
import Header from './pages/Header';
import Rotas from './Rotas';

function App() {
  return (
    <>
    <Header title="Gestão Apoie" />
    <Rotas />
    </>
  );
}

export default App;
