import React from 'react';
import {Container, Nav, Navbar } from 'react-bootstrap'
 
function Header(props) {
  return (
    <>
      <header>
       <h1>{props.title}</h1>
      </header>
      <Navbar bg="warning" expand="lg">
      <Container>
        <Navbar.Brand href="/lista">Início</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/planos">Planos</Nav.Link>
            <Nav.Link href="/login">Sair</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
  );
}
 
export default Header;