import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import properties from '../utils/properties';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'

function ListaPlanos(){
    const [data, setData] = useState([]);
    const [codPlan, setCodPlan] = useState([]);
    const [show, setShow] = useState(false);
    const [showActiv, setShowActiv] = useState(false);
    const [status, setStatus] = useState([]);
    let urlLista;
    let urlSuspend;
    let urlActiv;
    let token;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseActiv = () => setShowActiv(false);
    const handleShowActiv = () => setShowActiv(true);

    const config = { headers: { 'x-access-token': '' } };

    const cancelPlan = async () =>{
        token = localStorage.getItem('TOKEN_KEY');
        config.headers['x-access-token'] = token;
        urlSuspend = `${properties.server_host}/suspendeplano/${codPlan}`; 
        axios.get(urlSuspend, config).then(response => {
            console.log(response.data);
            setData(response.data);
            if (response.status === 200) {
                if(response.data.COD === 0) {
                  if (response.data.message) {
                    setStatus({ success: response.data.message });
                  }
                }
            }
            getPlanos();
            
        });
        handleClose();
    };

    const activPlan = async () =>{
        token = localStorage.getItem('TOKEN_KEY');
        config.headers['x-access-token'] = token;
        urlActiv = `${properties.server_host}/ativaplano/${codPlan}`; 
        axios.get(urlActiv, config).then(response => {
            console.log(response.data);
            setData(response.data);
            if (response.status === 200) {
                if(response.data.COD === 0) {
                  if (response.data.message) {
                    setStatus({ success: response.data.message });
                  }
                }
            }
            getPlanos();
        });
        handleCloseActiv();
    };
    
    const getPlanos = async () =>{
        token = localStorage.getItem('TOKEN_KEY');
        config.headers['x-access-token'] = token;
        urlLista = `${properties.server_host}/allplanos`; 
        axios.get(urlLista, config).then(response => {
            console.log(response.data);
            setData(response.data);
        })
    };

    useEffect(() => {
        token = localStorage.getItem('TOKEN_KEY');
        config.headers['x-access-token'] = token;
        getPlanos();
    }, []); 

    const exibePopupSuspend = async (code) =>{

        setCodPlan(code);
        handleShow();
        
    };

    const exibePopupAtiva = async (code) =>{

        setCodPlan(code);
        handleShowActiv();
        
    };

    return (
        <>
        <div>
        <h1>Planos</h1>
        <Alert variant="danger" >
            {status ? status.success : ''}
        </Alert>
        <Link to="/addplano">
            <button>Novo</button>
        </Link>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>ID</th>
                <th>Descrição</th>
                <th>Valor - R$</th>
                <th>Código</th>
                <th>Status</th>
                <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(data).map(plano => (
                <tr key={plano.id}>
                <td>{plano.id}</td>
                <td>{plano.descricao}</td>
                <td>{Number(plano.valor).toFixed(2)}</td>
                <td>{plano.codigo}</td>
                <td>{plano.status}</td>
                <td>
                {plano.status === 'ACTIVE' 
                    ? <Button variant="primary" onClick={() => exibePopupSuspend(plano.codigo)}> Suspender </Button>
                    : <Button variant="primary" onClick={() => exibePopupAtiva(plano.codigo)}> Ativar </Button>
                }
                </td>
                </tr>
            ))}
            </tbody>
        </Table>
    </div>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suspender Plano</Modal.Title>
        </Modal.Header>
        <Modal.Body> Antes de concluir esta ação, obtenha informação sobre qual a consequência para os assinantes deste plano <br></br><b>Tem certeza que deseja suspender este plano de assinatura?</b> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="danger" onClick={cancelPlan}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showActiv} onHide={handleCloseActiv}>
        <Modal.Header closeButton>
          <Modal.Title>Ativar Plano</Modal.Title>
        </Modal.Header>
        <Modal.Body><b> Tem certeza que deseja reativar este plano de assinatura?</b> </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseActiv}>
            Não
          </Button>
          <Button variant="danger" onClick={activPlan}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>


    </>
    );
}

export default ListaPlanos;