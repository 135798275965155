import React, {useEffect, useState} from 'react';
import { Form, Button, Row, Col, ListGroup, Card } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import properties from '../utils/properties';
import Alert from 'react-bootstrap/Alert'
import axios from 'axios';
import { useParams } from "react-router";

function ConsultaCliente(props){
    const { id } = useParams();
    console.log(id);
    const [status, setStatus] = useState([]);
    const [load, setLoad] = useState([]);
    const [assinante, setAssinante] = useState([]);
    const [pagamento, setPagamento] = useState([]);
    const loading = { type: 'bars', color: '#DAA520' };
    Alert.show = false;
    const dados = {
        id: 0,
        passwd: '',
      };
      let urlChangePwssd;
      let urlLista;
      let urlPagamento;
      const config = { headers: { 'x-access-token': '' } };
      let token;

      const getCliente = async () =>{
        urlLista = `${properties.server_host}/getassinante/${id}`; 
        axios.get(urlLista, config).then(response => {
            console.log(response.data);
            setAssinante(response.data);
        })
    };

    const getOrdemPagamento = async () =>{
      urlPagamento = `${properties.server_host}/getpagamentos/${assinante.code}`; 
      token = localStorage.getItem('TOKEN_KEY');
      config.headers['x-access-token'] = token;
      axios.get(urlPagamento, config).then(response => {
          console.log(response.data);
          if (response.status === 200) {
            if(response.data.COD === 0) {
              if (response.data.message) {
                setStatus({ success: response.data.message });
              } else {
                  setStatus({ success: 'Erro Buscando dados de Pagamentos' });
              }
            }else{
              setPagamento(response.data);
            }
          }
          
      }).catch(err => {
        console.log(err);
      }); 
  };

    useEffect(() => {
      token = localStorage.getItem('TOKEN_KEY');
      config.headers['x-access-token'] = token;
        getCliente();
    }, []); 
    
    const onChange = e => {
        console.log('entrou onchange');
        dados.passwd = e.target.value;
    };

    const submit = e => {
        console.log('entrou submit');
        setLoad({
            showLoader: true
          });
        e.preventDefault();
        const param = {id: id, passwd: dados.passwd};
        urlChangePwssd = `${properties.server_host}/updatepsswd`; 
        token = localStorage.getItem('TOKEN_KEY');
        config.headers['x-access-token'] = token;
        axios.post(urlChangePwssd, { param }, config).then((response) => {
            console.log(response);
        if (response.status === 200) {
          if (response.data.COD === 0) {
            if (response.data.message) {
              setStatus({ success: response.data.message });
            } else {
              setStatus({ success: 'Erro Atualizando Senha. Tente novamente mais tarde' });
            }
            setLoad({
              showLoader: false
            });
          } else if (response.data.COD === 10) {
            setStatus({ success: 'Senha Atualizado com Sucesso!' });
            setLoad({
              showLoader: false
            });
          }
        }
        }).catch(erro => {
          console.error('entrou erro', erro);
          if (erro.response.status === 500) {
            setStatus({ success: erro.response.data.message });
            setLoad({
              showLoader: false
            });
          } else {
            setStatus({ success: 'Erro Buscando Usuário' });
            setLoad({
              showLoader: false
            });
          }
        }); 
      };
   

    return (
        <>
        <div className="espacamento" >
            <ListGroup.Item >
                <Alert variant="danger" >
                    {status ? status.success : ''}
                </Alert>
            </ListGroup.Item>
                <ListGroup>
                  <ListGroup.Item>Nome: <b> {assinante.nome}</b></ListGroup.Item>
                  <ListGroup.Item>Nascimento: <b> {assinante.nascimento}</b></ListGroup.Item>
                  <ListGroup.Item>Sexo: <b> {assinante.sexo}</b></ListGroup.Item>
                  <ListGroup.Item>CPF: <b> {assinante.cpf}</b></ListGroup.Item>
                  <ListGroup.Item>Email: <b> {assinante.email}</b></ListGroup.Item>
                  <ListGroup.Item>Fone: <b> {assinante.celular}</b></ListGroup.Item>
                  <ListGroup.Item>Rua: <b> {assinante.rua}</b></ListGroup.Item>
                  <ListGroup.Item>Numero: <b> {assinante.numero}</b></ListGroup.Item>
                  <ListGroup.Item>Complemento: <b> {assinante.complemento}</b></ListGroup.Item>
                  <ListGroup.Item>Bairro: <b> {assinante.bairro}</b></ListGroup.Item>
                  <ListGroup.Item>Cidade: <b> {assinante.cidade}</b></ListGroup.Item>
                  <ListGroup.Item>CEP: <b> {assinante.cep}</b></ListGroup.Item>
                  <ListGroup.Item>UF: <b> {assinante.uf}</b></ListGroup.Item>
                  <ListGroup.Item>Status da Assinatura: <b> {assinante.desc_status_assinatura} </b></ListGroup.Item>
                  <ListGroup.Item>Plano de Assinatura: <b> {assinante.descricao} - R$ {assinante.valor},00 </b></ListGroup.Item>
                  <ListGroup.Item>
                    <Form onSubmit={submit}> 
                        <Row className="align-items-center">
                            <Col sm={3} className="my-1">
                                <Form.Label htmlFor="inlineFormInputName" >
                                    Senha
                                </Form.Label>
                            </Col>
                            <Col sm={3} className="my-1">
                                <Form.Control id="inlineFormInputName" type="password" name="password" onChange={onChange} />
                            </Col>
                            <Col sm={3}  className="my-1">
                                <Button variant="secondary" type="submit" >Alterar</Button>
                            </Col>
                            <Col sm="auto" className="my-1">
                                {load.showLoader && (
                                    <ReactLoading type={loading.type} color={loading.color} />
                                )}
                            </Col>
                        </Row>
                    </Form>
                  </ListGroup.Item>
                </ListGroup>
              <ListGroup>
                <ListGroup.Item>
                   <Button variant="secondary" onClick={getOrdemPagamento} >Pagamentos</Button>
                </ListGroup.Item>
                {Object.values(pagamento).map((ordem, index) => (
                  <div>
                  <Card>
                    <Card.Header> <b>Pagamento {index+1} </b> </Card.Header>
                    <Card.Body>
                      <ListGroup.Item>Código: <b>{ordem.code}</b></ListGroup.Item>
                      <ListGroup.Item>Situação: <b>{ordem.status}</b></ListGroup.Item>
                      <ListGroup.Item>Valor: <b>R$ {ordem.valor}</b></ListGroup.Item>
                      <ListGroup.Item>Última Data: <b>{ordem.ultima_data}</b></ListGroup.Item>
                      <ListGroup.Item>Próxima Data: <b>{ordem.proxima_data}</b></ListGroup.Item>
                    </Card.Body>
                  </Card>
                 </div>
                ))}
              </ListGroup>
        </div>
        </>
    );
}
 
export default ConsultaCliente;
