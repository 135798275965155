import React, { useState } from 'react';
import { useNavigate  } from "react-router-dom";
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button'
import properties from '../utils/properties';
import { Alert, Figure } from 'react-bootstrap';
import ReactLoading from 'react-loading';

function Login(){
    const navigate = useNavigate();
    const [status, setStatus] = useState([]);
    const [load, setLoad] = useState([]);
    const loading = { type: 'bars', color: '#DAA520' };
    Alert.show = false;

    const validSchema = Yup.object().shape({
        username: Yup.string('Usuário incorreto').max(255).required('Usuário é obrigatório'),
        password: Yup.string().max(255).required('Senha é obrigatória')
      });
    
      const formik = useFormik({
        initialValues: {
          username: '',
          password: ''
        },
        validationSchema: validSchema,
        onSubmit: (values) => {
          setLoad({
            showLoader: true
          });
          const user = {
            username: values.username,
            password: values.password
          };
          const urlLogin = `${properties.server_host}/loginusuario`;
          axios.post(urlLogin, user).then((resposta) => {
            console.log(resposta.data);
            if (resposta.data.auth === false) {
              setStatus({ success: 'Tempo de Login Expirado' });
              setLoad({
                showLoader: false
              });
            } else {
              setLoad({
                showLoader: false
              });
              const { usuario } = resposta.data;
              const { token } = resposta.data;
              localStorage.setItem('TOKEN_KEY', token);
              localStorage.setItem('PESSOA', usuario);
              navigate('/lista', { state: { usuario: usuario } });
            }
          }).catch((erro) => {
            console.error('entrou erro', erro);
            if (erro.response.status === 500) {
              setStatus({ success: erro.response.data.message });
              setLoad({
                showLoader: false
              });
            } else {
              setStatus({ success: 'Erro Autenticando Usuário' });
              setLoad({
                showLoader: false
              });
            }
          });
        }
      });

      const { errors, touched } = formik;

    return (
        <>
        <div className="login-wrapper">
      <h1>Login Gestão Apoie</h1>
      <Figure.Image
        alt="Brasao"
        src="logo400px.png"
      />
      <Alert variant="danger" >
            {status ? status.success : ''}
        </Alert>
      <form  onSubmit={formik.handleSubmit}>
        <label htmlFor="username">Usuário</label>
        <br></br>
            <input 
                id="username"
                name="username"
                type="text"
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur} 
                value={formik.values.username} />
            {errors.username && touched.username && (
            <span className="error">{errors.username}</span>
            )}
         <br></br>
        <label htmlFor="password">Valor</label>
        <br></br>
            <input 
                type="password" 
                name="password" 
                id="password"
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur} 
                value={formik.values.password} />
            {errors.password && touched.password && (
              <span className="error">{errors.password}</span>
            )}
            {load.showLoader && (
                        <ReactLoading type={loading.type} color={loading.color} />
            )}
            <br></br><br></br>
            <Button variant="secondary" type="submit" > Entrar </Button>
      </form>
    </div>




        </>
    );
}
 
export default Login;