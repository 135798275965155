import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import properties from '../utils/properties';
import moment from 'moment';

function ListaClientes(){
    const [data, setData] = useState([]);
    const config = { headers: { 'x-access-token': '' } };
    let token;
    let urlLista;

    const getClientes = async () =>{
        urlLista = `${properties.server_host}/getassinantes`; 
        axios.get(urlLista, config).then(response => {
            console.log(response.data);
            setData(response.data);
        })
    };

    useEffect(() => {
        token = localStorage.getItem('TOKEN_KEY');
        config.headers['x-access-token'] = token;
        getClientes();
    }, []); 

    return (
        <>
        <div>
        <h1>Assinantes</h1>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Nascimento</th>
                <th>Email</th>
                <th>Status</th>
                <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(data).map(assinante => (
                <tr key={assinante.id}>
                    <td>{assinante.id}</td>
                    <td>{assinante.nome}</td>
                    <td>{moment(assinante.nascimento).format('DD/MM/YYYY')}</td>
                    <td>{assinante.email}</td>
                    <td>{assinante.desc_status_assinatura}</td>
                    <td>
                    <Link to={`/consulta/${assinante.id}`}>
                        <Button variant="primary">
                           Exibir
                        </Button>
                    </Link>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    </div>
    

        </>
    );
}
 
export default ListaClientes;