import React from 'react';
import { Alert } from 'react-bootstrap';


function NotFound(){
   
    return (
    <>
        <div>  
     
        <Alert variant="danger" >
            PÁGINA NÃO ENCONTRADA
        </Alert>
     
        </div>
    </>
    );
}
 
export default NotFound;