import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ListaClientes from './pages/ListaClientes';
import ConsultaCliente from './pages/ConsultaCliente';
import ListaPlanos from './pages/ListaPlanos';
import AddPlano from './pages/AddPlano';
import Login from './pages/Login';
import NotFound from './pages/NotFound';

function Rotas(){
  return (
    <BrowserRouter>
      <Routes>
        <Route  path="/lista" element={<ListaClientes />} />
        <Route  path="/consulta/:id" element={<ConsultaCliente />} />
        <Route  path="/planos" element={<ListaPlanos />} />
        <Route  path="/addplano" element={<AddPlano />} />
        <Route  path="/login" element={<Login />} />
        <Route  path="/" exact  element={<Login />} />
        <Route  path="*" element={<NotFound />} />
        <Route  index element={<Login />} />
      </Routes>
    </BrowserRouter>
  );

}

export default Rotas;
